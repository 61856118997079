import React from "react";
import "./Latest-projects.css";
import SimpleImageSlider from "react-simple-image-slider";

const Latestprojects = () => {
  const NG_IMGS = [
    { url: "assets/Gardens/3.webp" },
    { url: "assets/Gardens/2.webp" },
    { url: "assets/Gardens/4.webp" },
    { url: "assets/Gardens/5.webp" },
    { url: "assets/Gardens/6.webp" },
    { url: "assets/Gardens/7.webp" },
    { url: "assets/Gardens/1.webp" },
  ];
  const NH_IMGS = [
    { url: "assets/neohaus-IMG/1.webp" },
    { url: "assets/neohaus-IMG/2.webp" },
    { url: "assets/neohaus-IMG/3.webp" },
    { url: "assets/neohaus-IMG/4.webp" },
    { url: "assets/neohaus-IMG/5.webp" },
    { url: "assets/neohaus-IMG/6.webp" },
    { url: "assets/neohaus-IMG/7.webp" },
    { url: "assets/neohaus-IMG/8.webp" },
    { url: "assets/neohaus-IMG/9.webp" },
    { url: "assets/neohaus-IMG/10.webp" },
    { url: "assets/neohaus-IMG/11.webp" },
    { url: "assets/neohaus-IMG/12.webp" },
    { url: "assets/neohaus-IMG/13.webp" },
    { url: "assets/neohaus-IMG/14.webp" },
    { url: "assets/neohaus-IMG/15.webp" },
    { url: "assets/neohaus-IMG/16.webp" },
  ];
  return (
    <div class="latest-project">
      <h1 className="LPage-H Orange">أحدث مشاريعنا السكنية</h1>
      {/* <h2>Neohaus نيــو هـــاوس</h2>
      <p>أكثر من وجهة سكنية؛ إنه تجسيد للتجديد، وتحية للتقدم، واحتفال ببدايات جديدة.</p>
      <img src={neohaus} alt=" neo hause project " className="latest-projects-image" />
      <p>شقق من غرفتين و وثلاث غرف نوم مساحات تبدأ من 118م2 الى 200 م2</p>
      <a href="https://neohaus.sa" className="latest-projects-button" target="_blank" rel="noopener noreferrer">زيارة المشروع</a> */}

      {/* NEOHAUS GARDENS */}

      {/* NEOHAUS */}
      <div className="latest-projects LP-p2">
        <div className="LPage-content">
          <h1 className="Green">نيو هاوس Neohaus</h1>

          <p className="Green">"حيث كل فجر جديد يمثل بداية جديدة"</p>
          <div className="LPage-Slider Mobile-ONLY">
            <SimpleImageSlider
              width={350}
              height={250}
              images={NH_IMGS}
              showNavs={true}
              navStyle={2}
              navMargin={"110"}
            />
          </div>
          <p>
            شقق من غرفتين وثلاث غرف نوم و مساحات تبدأ من 113م&#178; الى 298
            م&#178;
          </p>
         
          <a
            href="https://neohaus.sa"
            className="latest-projects-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            اكتشف
          </a>
        </div>
        <div className="LPage-Slider Desktop-ONLY">
          <SimpleImageSlider
            width={850}
            height={535}
            images={NH_IMGS}
            showNavs={true}
          />
        </div>
      </div>
      {/* NEOHAUS */}

      {/* NEOHAUS GARDENS */}
      <div className="latest-projects">
        <div className="LPage-content">
          <h1 className="Green">نيو هاوس قاردنز</h1>
          <p className="Green ">
            "أكثر من وجهة سكنية؛ إنه تجسيد للتجديد، وتحية للتقدم، واحتفال ببدايات جديدة."
          </p>
          <div className="LPage-Slider Mobile-ONLY">
            <SimpleImageSlider
              width={350}
              height={250}
              images={NG_IMGS}
              showNavs={true}
              navStyle={2}
              navMargin={"110"}
            />
          </div>
          <p>مجمع يحتوي على 41 تاون هاوس بمساحات تصل الى 512م&#178; </p>
          <button
            className="latest-projects-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            قريباً
          </button>
        </div>
        <div className="LPage-Slider Desktop-ONLY">
          <SimpleImageSlider
            width={850}
            height={535}
            images={NG_IMGS}
            // showBullets={true}
            showNavs={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Latestprojects;
