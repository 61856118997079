import React from "react";
import './about-aldiar.css';
import { Link } from "react-router-dom";
import logo from './../../../assets/logo.png';
import mainmobile from './../../../assets/BG-M.png';

const about = () => {
  return (
    <section className="hero">
    <Link to="/">
      <img className="mobile-logo" src={logo} alt="Logo" />
    </Link>
    <div className="hero-image">
    </div>
    <div className="hero-content">
      {/* <h1 >الديار المتقدمة</h1> */}
      <img src={logo} alt="Logo" className="MainPage-logo"/>
      <p className="MainPage-p BGC">
      أسست شركة الديار المتقدمة
      <span class=""> عام 2019 </span>
      لتكون إحدى
      <span class=""> رواد التطور</span>
       الحاصل في القطاع العقاري وذلك من خلال تقديم المنتجات السكنية ذات
      <span class=""> الجودة العالية </span>
       بتصاميم عصرية حديثة تواكب طموحات عملائها
      <span class=""> رؤيتنا </span>
        هي أن نحقق
      <span class=""> مفهوم العيش العصري </span>
       من خلال الابتكار والتميز في كافة مراحل تطوير مشاريعنا السكنية
      </p>
      <img src={mainmobile} className="MM-Image" alt="asdasdasdasd"/>
    </div>
  </section>
  );
};


export default about;