import React from "react";
import "./projects.css";
import SimpleImageSlider from "react-simple-image-slider";

const Projects = () => {
  const CPPS = [
    { url: "assets/latest-projects/Pro8.webp" },
    { url: "assets/latest-projects/Pro5.webp" },
    { url: "assets/latest-projects/Pro2.webp" },
    { url: "assets/latest-projects/Pro1.webp" },
    { url: "assets/latest-projects/Pro3.webp" },
    { url: "assets/latest-projects/Pro4.webp" },
    { url: "assets/latest-projects/Pro6.webp" },
    { url: "assets/latest-projects/Pro7.webp" },
    { url: "assets/latest-projects/Pro9.webp" },
  ];
  return (
    <div className="projects-page">
      <div className="PPage-content">
        <h1 className="Green PPage-H">مشاريعنا</h1>
        <p className="PPPPPPPPP">
          لدى <span class="">شركة الديار المتقدمة</span> قدرة عالية في
          استكمال مشاريعها حيث قامت الشركة بالانتهاء من تطوير أكثر من{" "}
          <span class="">169 وحدة</span> سكنية منذ العام 2021 في عدة مواقع
          مختلفة بمدينة الرياض تم تسليم أكثر من{" "}
          <span class="">123 وحدة</span> سكنية منها بالمواعيد المحددة كما
          قامت الشركة بتطوير هوية جديدة{" "}
          <span class=""> ( نيو هاوس ) NEOHAUS</span> لتكون هوية مجمعاتها
          السكنية الحديثة.
        </p>
      </div>
      {/* <img className='PPage-image' alt='image of neohaus' src={image} /> */}
      <div className="LPage-Slider Desktop-ONLY">
        <SimpleImageSlider
          width={850}
          height={566}
          images={CPPS}
          showNavs={true}
        />
      </div>
      <div className="LPage-Slider Mobile-ONLY">
        <SimpleImageSlider
          width={350}
          height={250}
          images={CPPS}
          showNavs={true}
          navStyle={2}
          navMargin={"110"}
        />
      </div>
    </div>
  );
};

export default Projects;
