import React from "react";
import { Link } from "react-router-dom";
import "./Contact.css";
import logo from "./../../../assets/logo.png";
import mainmobile from "./../../../assets/BG-M.png";

const Contact = () => {
  return (
    <section className="hero">
      <Link to="/">
        <img className="mobile-logo" src={logo} alt="Logo" />
      </Link>
      <div className="hero-image"></div>
      <div className="hero-content">
        <img src={logo} alt="Logo" className="MainPage-logo" />
        <div className="contact-info BGC">
          <div className="contact-item MainPage-p">
            <i className="fas fa-building"></i>
            <span style={{ paddingRight: "25px" }}>
              شركة الديار المتقدمة للتطوير والاستثمار العقاري
            </span>
          </div>
          <div className="contact-item MainPage-p">
            <i className="fas fa-map-marker-alt"></i>
            <span style={{ paddingRight: "25px" }}>
              8792 طريق عثمان بن عفان - حي الواحة
            </span>
          </div>
          <div className="contact-item MainPage-p">
            <i className="fas fa-phone"></i>
            <span style={{ paddingRight: "20px" }}>الهاتف: 0112635826</span>
          </div>
          <div className="contact-item MainPage-p">
            <i className="fas fa-envelope"></i>
            <span style={{ paddingRight: "13px" }}>
              البريد الإلكتروني:{" "}
              <a className="Normals" href="mailto:info@al-diar.sa">info@al-diar.sa</a>
            </span>
          </div>
        </div>
        <img src={mainmobile} className="MM-Image" alt="Main Mobile" />
      </div>
    </section>
  );
};

export default Contact;
