import React, { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./Menu.css";
import logo from "../../assets/Al Diar - Arabic Logo - Red.png";

export default function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="menu">
      <button className="burger-menu" onClick={toggleMenu}>
        ☰
      </button>
      <Link to="/" onClick={toggleMenu} className="MN-logo">
        <img src={logo} alt="Logo" />
      </Link>
      <ul className={` menu-list ${isOpen ? "open" : ""}`}>
        <CustomLink className={`menu-list-1`} to="/" onClick={toggleMenu}>
          الرئيسية
        </CustomLink>
        <CustomLink className={`menu-list-2`} to="/Latest" onClick={toggleMenu}>
          احدث المشاريع السكنية
        </CustomLink>
        <li className="logo menu-list-3">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </li>
        <CustomLink
          className={`menu-list-4`}
          to="/projects"
          onClick={toggleMenu}
        >
          مشاريعنا
        </CustomLink>
        <CustomLink className={`menu-list-5`} to="/About" onClick={toggleMenu}>
          عنا
        </CustomLink>
        <CustomLink
          className={`menu-list-6`}
          to="/ContactUS"
          onClick={toggleMenu}
        >
          تواصل معنا
        </CustomLink>
      </ul>
    </nav>
  );
}

function CustomLink({ to, children, className, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={`${className} ${isActive ? "active" : ""}`}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
