import React from "react";
import { Link } from "react-router-dom";
import './mainpage.css';
import logo from './../../../assets/logo.png';
import mainmobile from './../../../assets/BG-M.png';

const mainpage = () => {
  return (
    <section className="hero">
    <Link to="/">
      <img className="mobile-logo" src={logo} alt="Logo" />
    </Link>
    <div className="hero-image">
    </div>
    <div className="hero-content">
      {/* <h1 >الديار المتقدمة</h1> */}
      <img src={logo} alt="Logo" className="MainPage-logo"/>
      <p class="MainPage-p BGC">بخبرات متراكمة تمتد لأكثر من عقدين من الزمان و بالاستناد إلى أسس متينة وخبرات متميزة، تسعى شركة الديار المتقدمة الى تحقيق مفهوم العيش العصري من خلال الابتكار والتميز في كافة مراحل تطوير مشاريعها السكنية. بحيث تتبنى الشركة أحدث التصاميم والتقنيات لتطوير منازل فاخرة لضمان جودة حياة لساكني مشاريعها المتكاملة
      </p>
      <img src={mainmobile} className="MM-Image" alt="asdasdasdasd"/>
    </div>
  </section>
  );
};


export default mainpage;