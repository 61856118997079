import "./App.css";
import React from "react";
import Menu from "./components/Menu/Menu";
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mainpage from "./components/pages/mainpage/mainpage";
import Projects from "./components/pages/projects/projects";
import About from "./components/pages/about-aldiar/about-aldiar";
import Latest from "./components/pages/Latest-projects/Latest-projects";
import ContactUS from "./components/pages/Contact/Contact";

function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <Menu />
        </header>

        <Routes>
          <Route path="/" element={<Mainpage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/About" element={<About />} />
          <Route path="/Latest" element={<Latest />} />
          <Route path="/ContactUS" element={<ContactUS />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
